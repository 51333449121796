<template>
  <div>

    <!-- Filter -->
    <v-card v-if="filtersTitle" class="mb-4 pb-4 px-2">
      <v-card-title>{{ filtersTitle }}</v-card-title>

      <div class="d-flex flex-row flex-wrap">
        <v-card-text class="col-md-4" v-for="filter in filterFields" :key="filter.value">
          
          <component v-if="filter.component == 'v-select' " :is="filter.component" :label="filter.label" :items="filter.items"
            @change="(e) => handleInput(e, filter.value)" dense
            hide-details outlined></component>

            <component v-else :is="filter.component" :label="filter.label" :value="state.filters[filter.value]"
            @input="(e) => handleInput(e, filter.value)" @change="(e) => handleInput(e, filter.value)" dense
            hide-details outlined></component>

        </v-card-text>

      </div>

      <v-card-actions>
        <v-btn @click="applyFilters" color="primary pr-4 mr-2 ml-1">
         <v-icon class="mr-1" size="20">{{ icons.mdiFilter   }}</v-icon>
         <span>Filter</span>
        </v-btn>
        <v-btn @click="clearFilters" class="pr-4">
         <v-icon class="mr-1" size="20">{{ icons.mdiReload  }}</v-icon>
         <span>Reset</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Table Data -->
    <v-card>
      <v-data-table 
      :headers="tableHeaders"
        :items="state.tableData"
        :loading="state.isLoading"
        :page.sync="state.pagination.page"
        :options.sync="state.pagination"
        :server-items-length="state.pagination.serverItemsLength"
        sort-desc.sync="false"
        @update:sort-by="handleSortType"
        @update:sort-desc="handleSort"
        @update:page="handlePageChange"
        no-data-text="No Data"        
        class="elevation-1">
        <template v-for="column in customColumns" v-slot:[`item.${column.itemValue}`]="{ item }">
          <slot :name="column.slotName" :item="item">
          </slot>
        </template>

      </v-data-table>
    </v-card>

    
  </div>
</template>

<script>
import axios from "@axios";
import { onMounted, reactive, ref,inject,watch } from "@vue/composition-api";
import { VTextField, VCheckbox,VSelect } from "vuetify/lib";
import { mdiFilterVariant,mdiReload ,mdiFilterSettings,mdiFilter  } from '@mdi/js';

export default {
  name: "TableWithFilters",
  props: { options: Object, customColumns: Array },
  components: { VTextField, VCheckbox ,VSelect},
  setup({ options },{emit}) {



    const state = reactive({
      endpoint: "",
      isLoading: false,
      filters: {},
      tableData: [],
      sortdir:'',
      sortby:'',
      pagination:{
        page: 1,
        itemsPerPage: 0,
        mustSort: true,
        serverItemsLength:0
    }
  });

  const childMethods = inject('childMethods');

  onMounted(() => {
      state.endpoint = options.endpoint;
      if (options.filterFields)
        for (var filter of options.filterFields) {
          state.filters[filter.value] = "";
        }

      fetchTableData();
    });

    async function fetchTableData() {
      try {
        state.isLoading = true;
        const { data:accounts } = await axios.get(state.endpoint, {
          params: { page: state.pagination.page, ...state.filters,sortby:state.sortby,sortdir:state.sortdir},
        });
        console.log("Accounts Table : ",accounts.data.users)
        state.tableData = accounts.data.users;
        state.pagination.page = parseInt(accounts.data.page);
        state.pagination.itemsPerPage = accounts.data.itemsPerPage;
        state.pagination.serverItemsLength = accounts.data.total;
      } catch (err) {
        console.log(err);
        throw err
      } finally {
        state.isLoading = false;
      }
    }

    function handleInput(e, target) {
      state.filters[target] = e;
    }

    function handlePageChange(event) {
      state.pagination.page = parseInt(event);
      fetchTableData();
    }

    function handleSort(event){
      if(event == false){
        state.sortdir = 'asc'
      }
      else{
        state.sortdir = 'desc'
      }
      fetchTableData();
    }

    function handleSortType(event){
      state.sortby = event
      console.log('handleSortType',event)
    }

    function applyFilters() {
      state.pagination.page = 1;
      fetchTableData();
    }

    function clearFilters() {
      state.pagination.page = 1;
      for (var f in state.filters) {
        state.filters[f] = "";
      }
      fetchTableData();
    }

    // const refreshWallet = async(userID,walletID,ssmID) => {
    //   console.log("refreshWallet",userID,walletID,ssmID)
    //   try {
    //     const { data: wallet,message } = await axios.get(`/wallet/refresh-wallet/${walletID}/${ssmID}`);
    //     const index = state.tableData.findIndex(u=>u.id == userID)
    //     console.log(index,wallet.message,wallet.data)
    //     state.tableData[index].wallet.amount = wallet.data.minsRemaining;
    //     emit('refresh',false,wallet.message)
    //     return wallet;
    //   } catch (error) {
    //     console.log(" tableWithFilter refreshWallet:Error", error.response.data.message);
    //     emit('refresh',false,error.response.data.message)
    //   }
    // }

    // if (childMethods) {
    //   childMethods.value = { refreshWallet };
    // }

    return {
      // refreshWallet,
      state,
      filtersTitle: options.filtersTitle,
      filterFields: options.filterFields,
      tableHeaders: options.headers,

      fetchTableData,
      handlePageChange,
      handleInput,
      applyFilters,
      clearFilters,
      handleSort,
      handleSortType,
      icons:{
        mdiFilterVariant,
        mdiReload,
        mdiFilterSettings,
        mdiFilter 
      }
    };
  },
};
</script>

<style>
.v-data-footer__pagination {
  margin-left: auto !important;
}

.v-data-footer__select {
  display: none;
}

.box {
  display: flex;
  gap: 2rem;
  align-items: center;
}
</style>
